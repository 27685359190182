import { FlashList } from '@shopify/flash-list';
import { useMemo, useState } from 'react';
import { Dimensions } from 'react-native';

import { useMqSelect } from '../../mq-styles';
import { tokens } from '../../tokens';

import { useAnchorSectionList } from './context';
import { SectionListHeader } from './section-list-header';
import { SectionListItem } from './section-list-item';

export type SectionListProps = {};

export function SectionList(_props: SectionListProps) {
  const context = useAnchorSectionList();

  const estimatedItemSize = useMqSelect({ $gteDesktop: 220 }, 180);
  const horizontalPadding = useMqSelect({ $gteDesktop: 32 }, 16);
  const verticalPadding = useMqSelect({ $gteDesktop: 28 }, 20);

  const [availableWidth, setAvailableWidth] = useState(Dimensions.get('window').width);

  const numColumns = useMemo(() => {
    return Math.max(Math.floor((availableWidth - horizontalPadding * 2) / estimatedItemSize), 1);
  }, [availableWidth, horizontalPadding, estimatedItemSize]);

  const extraData = useMemo(() => ({ numColumns, size: context.size }), [numColumns, context.size]);

  return (
    <FlashList
      onLayout={event => setAvailableWidth(event.nativeEvent.layout.width)}
      ref={context.flashListRef}
      horizontal={false}
      data={context.flashListData}
      contentContainerStyle={{
        backgroundColor: tokens.colors.$white,
        paddingHorizontal: horizontalPadding,
        paddingVertical: verticalPadding,
      }}
      estimatedItemSize={estimatedItemSize}
      renderItem={({ item: datum }) => {
        if (datum.type === 'item') {
          return (
            <SectionListItem
              item={datum.item}
              size={context.size}
              isFirstColumn={datum.indexWithinSection % numColumns === 0}
              isLastColumn={datum.indexWithinSection % numColumns === numColumns - 1}
            />
          );
        }

        if (datum.type === 'section') {
          return <SectionListHeader title={datum.section.title} />;
        }

        if (datum.type === 'custom') {
          return datum.section.items;
        }
      }}
      numColumns={numColumns}
      extraData={extraData}
      overrideItemLayout={(layout, item, index, maxColumns, _extraData) => {
        if (item.type === 'section' || item.type === 'custom') {
          layout.span = maxColumns;
          return;
        }

        return;
      }}
      getItemType={item => item.type}
      onViewableItemsChanged={({ viewableItems }) => {
        const firstViewableSection = viewableItems.find(
          ({ item }) => item.type === 'section' || item.type === 'custom'
        );
        if (firstViewableSection) {
          context.setActiveSectionId(firstViewableSection.item?.section?.id);
          return;
        }

        const firstViewableItem = viewableItems.find(({ item }) => item.type === 'item');

        if (firstViewableItem) {
          context.setActiveSectionId(firstViewableItem.item?.parentSectionId);
        }
      }}
    />
  );
}
