import { type ComponentProps, useMemo } from 'react';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';

import { AnchorSectionList, MqSwitch, XStack } from '@fhs/ui';

import { MenuListSidebar } from '../components/menu-list-sidebar';
import { MenuListTopbar } from '../components/menu-list-topbar';
import { MenuOffersLink } from '../components/menu-offers-link';
import { getMenuListData } from '../data/menu-list-data';
import type { ScreenMenuListProps } from '../types';

export function ScreenMenuList(props: ScreenMenuListProps) {
  const sections: ComponentProps<typeof AnchorSectionList>['sections'] = useMemo(
    () => [
      {
        id: 'offers-and-rewards',
        title: '',
        anchorTitle: 'Offers & Rewards',
        image: {
          asset: { uri: 'https://placehold.co/40x40' },
          altText: 'A placeholder image',
        },
        items: <MenuOffersLink />,
      },
      ...props.sections.map(rawSection => {
        return {
          id: rawSection.slug,
          title: rawSection.displayName,
          anchorTitle: rawSection.displayName,
          image: {
            asset: { uri: 'https://placehold.co/40x40' },
            altText: 'A placeholder image',
          },
          items: rawSection.items.map(rawItem => {
            return {
              id: rawItem.slug,
              href: rawItem.url,
              title: rawItem.displayName,
              description: rawItem.descriptionShort,
              badge: rawItem.status,
              image: {
                asset: { uri: rawItem.imageUrl },
                altText: '',
              },
              isAvailable: rawItem.isAvailable,
            };
          }),
        };
      }),
    ],
    [props.sections]
  );

  return (
    <AnchorSectionList sections={sections}>
      <MqSwitch
        $base={() => (
          <View style={styles.container}>
            <MenuListTopbar>
              <AnchorSectionList.AnchorListHorizontal />
            </MenuListTopbar>
            <AnchorSectionList.SectionList />
          </View>
        )}
        $gteDesktop={() => (
          <View style={styles.container}>
            <XStack style={styles.xStack}>
              <MenuListSidebar>
                <AnchorSectionList.AnchorListVertical />
              </MenuListSidebar>
              <AnchorSectionList.SectionList />
            </XStack>
          </View>
        )}
      />
    </AnchorSectionList>
  );
}

export function ScreenMenuListWithMock() {
  const { sections } = getMenuListData('0001');

  return <ScreenMenuList sections={sections} />;
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
  },
  xStack: {
    height: '100%',
  },
});
