export const sizeLabelMapping = {
  'small-sandwich': {
    label: 'Small',
    description: '$7.99',
  },
  'medium-sandwich': {
    label: 'Medium',
    description: '$7.99',
  },
  'large-sandwich': {
    label: 'Large',
    description: '$7.99',
  },
} as const;
