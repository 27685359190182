import { Image, ImageBackground } from 'expo-image';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, ScrollView, Text, YStack, tokens } from '@fhs/ui';
import { ToastVariant, useToast } from '@fhs-legacy/universal-components';

import type { MenuItem } from '../types';

import { ComboUpSell } from './combo-up-sell';
import { IngredientReplacement } from './ingredient-replacement';
import { sizeLabelMapping } from './ingredient-replacement/constants';
import { ProductDetailSectionHeader } from './product-detail-section-header';
import { SizeSelector } from './size-selector';

type MenuSectionProps = {
  item: MenuItem;
};

export function MenuItemDetails(props: MenuSectionProps) {
  const { show } = useToast({
    placement: 'bottom',
  });
  const { item } = props;
  const [sizeValue, setSizeValue] = useState<keyof typeof sizeLabelMapping>('medium-sandwich');
  const [comboUpSell, setComboUpSell] = useState<boolean>(false);
  const [ingredientReplacement, setIngredientReplacement] = useState<boolean>(false);

  const handleSizeChange = (value: keyof typeof sizeLabelMapping) => {
    setSizeValue(value);
  };

  const triggerToast = (id: string, variant: ToastVariant) => {
    show({
      id: id,
      variant: variant,
      text: "I'm a fake toast",
    });
  };

  return (
    <ScrollView style={styles.container}>
      <YStack style={styles.containerPadding}>
        <YStack style={styles.containerTitle}>
          <Text.Heading type="one" style={styles.textTitle}>
            {item.displayName}
          </Text.Heading>
          <Text.Heading type="four" style={styles.textSubTitle}>
            Medium Size • 720 Cal
          </Text.Heading>
        </YStack>
        <ImageBackground
          contentFit="contain"
          source={require('../assets/img/menu_item_detail_bg_2477_1058.webp')}
          style={styles.containerImageBackground}
        >
          <Image source={{ uri: item.imageUrl }} contentFit="contain" style={styles.image} />
        </ImageBackground>
        <Text.Paragraph size="md" style={styles.textDescription}>
          {item.description}
        </Text.Paragraph>
      </YStack>
      <YStack style={[styles.containerSection, styles.containerPadding]}>
        <Text.Heading type="four">Size</Text.Heading>
      </YStack>
      <SizeSelector value={sizeValue} onChange={handleSizeChange} />
      <YStack style={[styles.containerSection, styles.containerPadding]}>
        <Text.Heading type="four">Choices</Text.Heading>
      </YStack>
      <View style={{ padding: 16 }}>
        <Button
          style={styles.dispatchButton}
          onPress={() => {
            setComboUpSell(prev => !prev);
          }}
          aria-label="Dispatch ComboUpSell"
          size="sm"
        >
          <Button.Text>Dispatch ComboUpSell</Button.Text>
        </Button>
      </View>
      <View style={{ padding: 16 }}>
        <Button
          style={styles.dispatchButton}
          onPress={() => {
            setIngredientReplacement(prev => !prev);
          }}
          aria-label="Dispatch IngredientReplacement"
          size="sm"
        >
          <Button.Text>Dispatch IngredientReplacement</Button.Text>
        </Button>
      </View>
      <View style={{ padding: 16 }}>
        <Button
          style={styles.dispatchButton}
          onPress={() => {
            triggerToast('fake-positive-toast-id', 'positive');
          }}
          aria-label="Dispatch Positive Toast"
          size="sm"
        >
          <Button.Text>Dispatch Positive Toast</Button.Text>
        </Button>
      </View>
      <View style={{ padding: 16 }}>
        <Button
          style={styles.dispatchButton}
          onPress={() => {
            triggerToast('fake-negative-toast-id', 'negative');
          }}
          aria-label="Dispatch Negative Toast"
          size="sm"
        >
          <Button.Text>Dispatch Negative Toast</Button.Text>
        </Button>
      </View>
      <ComboUpSell
        headerTitle="Make it a Combo"
        acceptButtonTitle="Make it a Combo"
        closeButtonTitle="No, thanks"
        description="Add any size drink + chips or cookie for only"
        price="$3.49"
        isVisible={comboUpSell}
        handleAccept={() => setComboUpSell(false)}
        handleClose={() => setComboUpSell(false)}
      />
      <IngredientReplacement
        headerTitle="Ingredient Replacement"
        acceptButtonTitle="Replace"
        closeButtonTitle="Cancel"
        items={[
          {
            from: {
              image:
                'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/03c4a4563f43f8cee8c7712a7ac97489232fc744-1699x2467.png?sat=-100',
              name: 'Gluten Free Roll',
              blurhash: 'dmS5^h%2.TNdxuayWBoL.9R*McxZaej[ofayozj[V@f6',
            },
            to: {
              image:
                'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/e16be18aa34944f6642ab890053c9b85725ade84-1640x2556.png',
              name: 'White Roll',
              blurhash: 'dwRovu%L.TI:off6bHfk%$RkMwxaoffkjZf6tRayRPoe',
            },
          },
        ]}
        isVisible={ingredientReplacement}
        sizeLabel={sizeLabelMapping[sizeValue].label}
        handleReplace={() => setIngredientReplacement(false)}
        handleClose={() => setIngredientReplacement(false)}
      />
      <ProductDetailSectionHeader
        title="Make it a Combo"
        subtitle="Add any size drink + chips or cookie"
        style={styles.productDetailSectionHeader}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    // TOOD(simplified-menu): Use media query
    backgroundColor: tokens.colors.$white,
    alignSelf: 'center',
    // TODO(simplified-menu): Update to use tokens, or add Media Query
    maxWidth: 620,
  },
  containerPadding: {
    paddingHorizontal: 16,
  },
  containerTitle: {
    marginVertical: 8,
  },
  textTitle: {
    textAlign: 'center',
  },
  textSubTitle: {
    textAlign: 'center',
  },
  textSectionTitle: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 20,
    paddingVertical: 8,
  },
  containerImageBackground: {
    flex: 1,
    minHeight: 200,
    paddingVertical: 18,
  },
  image: {
    height: '100%',
    paddingVertical: 18,
  },
  textDescription: {
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  containerSection: {
    marginVertical: 16,
  },
  productDetailSectionHeader: {
    padding: 16,
    gap: 16,
  },
  dispatchButton: {
    padding: 16,
    gap: 16,
  },
});
