import { StyleSheet } from 'react-native';

import { ScrollView, type ScrollViewProps, Text, tokens } from '@fhs/ui';

export type MenuListSidebarProps = ScrollViewProps;

export function MenuListSidebar(props: MenuListSidebarProps) {
  return (
    <ScrollView
      {...props}
      style={[styles.sidebarScroller, props.style]}
      contentContainerStyle={[styles.sidebarContent, props.contentContainerStyle]}
    >
      <>
        <Text.Paragraph size="md" weight="semibold">
          Menu
        </Text.Paragraph>
        {props.children}
      </>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  sidebarScroller: {
    height: '100%',
    width: 312,
    flexGrow: 0,
    borderRightWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    backgroundColor: tokens.colors.$white,
  },
  sidebarContent: {
    padding: 32,
    gap: 12,
  },
});
