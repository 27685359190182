{
  "sections": [
    {
      "id": "c2663ed0-a388-45da-b582-5a97f9a2ed6c",
      "displayName": "Firehouse Snackers",
      "description": "",
      "slug": "firehouse-snackers",
      "url": "/v2/menu?firehouse-snackers",
      "items": [
        {
          "id": "03d0fff1-657d-495f-b4cb-d4d9053b0f32",
          "slug": "blt",
          "url": "/v2/menu/blt",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/b576f9bfef003e9f7cc369000d7f6e9236645066-500x333.png?w=275&q=80&fit=max&auto=format",
          "displayName": "BLT",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "picker-03d0fff1-657d-495f-b4cb-d4d9053b0f32",
          "__legacyUrl": "/menu/picker-03d0fff1-657d-495f-b4cb-d4d9053b0f32"
        },
        {
          "id": "bde6da64-105a-4180-bb7f-8c3bdcf1cd8a",
          "slug": "sweet-and-spicy-ham",
          "url": "/v2/menu/sweet-and-spicy-ham",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/8b0ebe3a134cc256441dafd6af851c0c4c62f4a4-500x335.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Sweet and Spicy Ham",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "picker-bde6da64-105a-4180-bb7f-8c3bdcf1cd8a",
          "__legacyUrl": "/menu/picker-bde6da64-105a-4180-bb7f-8c3bdcf1cd8a"
        },
        {
          "id": "b67dac22-8d15-46a4-8e21-ba64168542ba",
          "slug": "cordon-bleu",
          "url": "/v2/menu/cordon-bleu",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/bab859a23882336b113f417ad1c23599d6b10081-500x333.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Cordon Bleu",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "picker-b67dac22-8d15-46a4-8e21-ba64168542ba",
          "__legacyUrl": "/menu/picker-b67dac22-8d15-46a4-8e21-ba64168542ba"
        },
        {
          "id": "83115f5f-6227-4070-a0eb-0b843ed7bacc",
          "slug": "turkey-jalapeo",
          "url": "/v2/menu/turkey-jalapeo",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/a18402f856a63a3f97c77d38c5fbb91a7d37295c-500x333.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Turkey Jalapeño",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "picker-83115f5f-6227-4070-a0eb-0b843ed7bacc",
          "__legacyUrl": "/menu/picker-83115f5f-6227-4070-a0eb-0b843ed7bacc"
        }
      ]
    },
    {
      "id": "62af9328-1cdb-4935-b849-c5c84b9be160",
      "displayName": "New! BBQ Cuban Sub",
      "description": "",
      "slug": "new-bbq-cuban-sub",
      "url": "/v2/menu?new-bbq-cuban-sub",
      "items": [
        {
          "id": "9b56faf3-2b97-453b-ae34-0dd57e853083",
          "slug": "new-bbq-cuban",
          "url": "/v2/menu/new-bbq-cuban",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/402cb5f1e48f8035f7842e30713b4bc4b0d91576-1340x783.png?w=275&q=80&fit=max&auto=format",
          "displayName": "New! BBQ Cuban",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-9b56faf3-2b97-453b-ae34-0dd57e853083",
          "__legacyUrl": "/menu/picker-9b56faf3-2b97-453b-ae34-0dd57e853083"
        }
      ]
    },
    {
      "id": "ede481b6-d287-47df-921f-886db7cb0036",
      "displayName": "New! French Fries",
      "description": "",
      "slug": "new-french-fries",
      "url": "/v2/menu?new-french-fries",
      "items": [
        {
          "id": "0fdb67e6-fe3d-4adf-ad13-abb5828b2b00",
          "slug": "new-french-fries",
          "url": "/v2/menu/new-french-fries",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/b8086bbf3be0ae11fbc039a703955c2eb999b396-500x333.png?w=275&q=80&fit=max&auto=format",
          "displayName": "New! French Fries",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "picker-0fdb67e6-fe3d-4adf-ad13-abb5828b2b00",
          "__legacyUrl": "/menu/picker-0fdb67e6-fe3d-4adf-ad13-abb5828b2b00"
        }
      ]
    },
    {
      "id": "f062ca25-583a-487e-be17-e9b189da4350",
      "displayName": "Hot Specialty Subs",
      "description": "",
      "slug": "hot-specialty-subs",
      "url": "/v2/menu?hot-specialty-subs",
      "items": [
        {
          "id": "9b56faf3-2b97-453b-ae34-0dd57e853083",
          "slug": "new-bbq-cuban",
          "url": "/v2/menu/new-bbq-cuban",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/402cb5f1e48f8035f7842e30713b4bc4b0d91576-1340x783.png?w=275&q=80&fit=max&auto=format",
          "displayName": "New! BBQ Cuban",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-9b56faf3-2b97-453b-ae34-0dd57e853083",
          "__legacyUrl": "/menu/picker-9b56faf3-2b97-453b-ae34-0dd57e853083"
        },
        {
          "id": "8582c902-7477-4396-9ac4-4e1262ee37d8",
          "slug": "chicken-parmesan-meatball-sub",
          "url": "/v2/menu/chicken-parmesan-meatball-sub",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/024edbce148e4183578cc967ba3c8381fa9e715d-577x433.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Chicken Parmesan Meatball Sub",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "picker-8582c902-7477-4396-9ac4-4e1262ee37d8",
          "__legacyUrl": "/menu/picker-8582c902-7477-4396-9ac4-4e1262ee37d8"
        },
        {
          "id": "6400312a-772c-4f17-81db-4ab28f252c48",
          "slug": "pepperoni-pizza-meatball-sub",
          "url": "/v2/menu/pepperoni-pizza-meatball-sub",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/477255208735823afcf2490610a638210e39e95c-685x364.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Pepperoni Pizza Meatball Sub",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-6400312a-772c-4f17-81db-4ab28f252c48",
          "__legacyUrl": "/menu/picker-6400312a-772c-4f17-81db-4ab28f252c48"
        },
        {
          "id": "57783225-461a-409b-8983-af4b1be9cce1",
          "slug": "hook-ladder",
          "url": "/v2/menu/hook-ladder",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/fa6c8847b2c287b8329b4dd6e1577ed87d60e568-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Hook & Ladder ®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-57783225-461a-409b-8983-af4b1be9cce1",
          "__legacyUrl": "/menu/picker-57783225-461a-409b-8983-af4b1be9cce1"
        },
        {
          "id": "48b9643c-eebc-42ff-87a6-1429422ada68",
          "slug": "firehouse-meatball",
          "url": "/v2/menu/firehouse-meatball",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/f43df6fc9461e6efadb95a760407994e5c33b9f1-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Firehouse Meatball®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-48b9643c-eebc-42ff-87a6-1429422ada68",
          "__legacyUrl": "/menu/picker-48b9643c-eebc-42ff-87a6-1429422ada68"
        },
        {
          "id": "b292a31f-7b61-4764-9335-b31bc5431822",
          "slug": "sweet-spicy-meatball",
          "url": "/v2/menu/sweet-spicy-meatball",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/5e9da9d1c41cfd6581d88e0a9b2d1c4467f393ac-500x325.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Sweet & Spicy Meatball",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-b292a31f-7b61-4764-9335-b31bc5431822",
          "__legacyUrl": "/menu/picker-b292a31f-7b61-4764-9335-b31bc5431822"
        },
        {
          "id": "6ecd9293-3451-4674-89a4-94c6659d9a79",
          "slug": "new-york-steamer",
          "url": "/v2/menu/new-york-steamer",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/5593db4167c92e624bc65cebff7ee3268ae1a639-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "New York Steamer®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-6ecd9293-3451-4674-89a4-94c6659d9a79",
          "__legacyUrl": "/menu/picker-6ecd9293-3451-4674-89a4-94c6659d9a79"
        },
        {
          "id": "662b4dce-b687-4d28-9e1f-c6bfe738ca00",
          "slug": "italian",
          "url": "/v2/menu/italian",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/2c8a12d7c7223b2ce83553ee4291b522ee0fcf98-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Italian",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-662b4dce-b687-4d28-9e1f-c6bfe738ca00",
          "__legacyUrl": "/menu/picker-662b4dce-b687-4d28-9e1f-c6bfe738ca00"
        },
        {
          "id": "84910892-3a3f-430e-b7fb-31d8a7b10c00",
          "slug": "firehouse-steak-cheese",
          "url": "/v2/menu/firehouse-steak-cheese",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/3e50274d7988bfdba693268dcb20f36e249dc94b-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Firehouse Steak & Cheese®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-84910892-3a3f-430e-b7fb-31d8a7b10c00",
          "__legacyUrl": "/menu/picker-84910892-3a3f-430e-b7fb-31d8a7b10c00"
        },
        {
          "id": "2cd2f06e-e698-47fe-8afd-c01a02a33e5b",
          "slug": "smokehouse-beef-cheddar-brisket",
          "url": "/v2/menu/smokehouse-beef-cheddar-brisket",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/13daa72a19dbb736ddd129c0114ee57649a46563-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Smokehouse Beef & Cheddar Brisket®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-2cd2f06e-e698-47fe-8afd-c01a02a33e5b",
          "__legacyUrl": "/menu/picker-2cd2f06e-e698-47fe-8afd-c01a02a33e5b"
        },
        {
          "id": "2488a777-03fb-44aa-a9b6-85b6cded6c8d",
          "slug": "engineer",
          "url": "/v2/menu/engineer",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/b4ce9cc47ee86e3632c17f9f772e03dd6d5154ca-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Engineer®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-2488a777-03fb-44aa-a9b6-85b6cded6c8d",
          "__legacyUrl": "/menu/picker-2488a777-03fb-44aa-a9b6-85b6cded6c8d"
        },
        {
          "id": "d58a752c-ef43-4f6e-8374-2364a87edf7e",
          "slug": "club-on-a-sub",
          "url": "/v2/menu/club-on-a-sub",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/571df98228ad82b992599a8960aa85aebc732eab-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Club on a Sub",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-d58a752c-ef43-4f6e-8374-2364a87edf7e",
          "__legacyUrl": "/menu/picker-d58a752c-ef43-4f6e-8374-2364a87edf7e"
        },
        {
          "id": "de7f66ff-f3e2-4f27-8596-8d2fd99a6df7",
          "slug": "turkey-bacon-ranch",
          "url": "/v2/menu/turkey-bacon-ranch",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/319f47b5468bc12aaf0387df70b4b65aafa06fe2-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Turkey Bacon Ranch",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-de7f66ff-f3e2-4f27-8596-8d2fd99a6df7",
          "__legacyUrl": "/menu/picker-de7f66ff-f3e2-4f27-8596-8d2fd99a6df7"
        },
        {
          "id": "0b1e453a-526f-450b-9fa4-cbf815857992",
          "slug": "firehouse-hero",
          "url": "/v2/menu/firehouse-hero",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/bd16a962ed9184a836ad37d498978d8ec4db7a9d-500x400.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Firehouse Hero®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-0b1e453a-526f-450b-9fa4-cbf815857992",
          "__legacyUrl": "/menu/picker-0b1e453a-526f-450b-9fa4-cbf815857992"
        },
        {
          "id": "0f5460c4-126e-4f65-825e-5c15924ab3dd",
          "slug": "spicy-cajun-chicken",
          "url": "/v2/menu/spicy-cajun-chicken",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/4886b6d30061eb223f0a4b87cb53dbc33797ea24-890x518.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Spicy Cajun Chicken",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-0f5460c4-126e-4f65-825e-5c15924ab3dd",
          "__legacyUrl": "/menu/picker-0f5460c4-126e-4f65-825e-5c15924ab3dd"
        },
        {
          "id": "1192951e-b6d6-4aa9-9720-bd51b59b4ea9",
          "slug": "jamaican-jerk-turkey",
          "url": "/v2/menu/jamaican-jerk-turkey",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/d6d3e496e0ae868e247fdf792b44a55fc8cf3cde-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Jamaican Jerk Turkey",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-1192951e-b6d6-4aa9-9720-bd51b59b4ea9",
          "__legacyUrl": "/menu/picker-1192951e-b6d6-4aa9-9720-bd51b59b4ea9"
        }
      ]
    },
    {
      "id": "65fc9209-226e-4b8e-8e11-95104f2eb720",
      "displayName": "Build Your Own Sub",
      "description": "",
      "slug": "build-your-own-sub",
      "url": "/v2/menu?build-your-own-sub",
      "items": [
        {
          "id": "ad099d99-ba2a-4fb3-8dab-db69c5a1af63",
          "slug": "smoked-turkey-provolone",
          "url": "/v2/menu/smoked-turkey-provolone",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/98a08fdb9f0eb1688096d746f9d908a653eac39d-550x358.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Smoked Turkey & Provolone",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-ad099d99-ba2a-4fb3-8dab-db69c5a1af63",
          "__legacyUrl": "/menu/picker-ad099d99-ba2a-4fb3-8dab-db69c5a1af63"
        },
        {
          "id": "0c3c0c68-c260-45d6-9755-1506f491d39c",
          "slug": "honey-ham",
          "url": "/v2/menu/honey-ham",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/57931666afdb093e011b8024092dd8027d981dbc-600x390.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Honey Ham",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-0c3c0c68-c260-45d6-9755-1506f491d39c",
          "__legacyUrl": "/menu/picker-0c3c0c68-c260-45d6-9755-1506f491d39c"
        },
        {
          "id": "2cae3e74-8cad-4aa0-85b0-5f97cc4ee05d",
          "slug": "corned-beef",
          "url": "/v2/menu/corned-beef",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/03d9ed3d62f3147de715343ea589c8372ae6d6df-600x390.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Corned Beef",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-2cae3e74-8cad-4aa0-85b0-5f97cc4ee05d",
          "__legacyUrl": "/menu/picker-2cae3e74-8cad-4aa0-85b0-5f97cc4ee05d"
        },
        {
          "id": "75a03d92-61cc-42ac-8c40-ace4b10fbc8b",
          "slug": "pastrami",
          "url": "/v2/menu/pastrami",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/c28b4862cae14b8b1692fd84eeecc07872d5bf9f-550x358.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Pastrami",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-75a03d92-61cc-42ac-8c40-ace4b10fbc8b",
          "__legacyUrl": "/menu/picker-75a03d92-61cc-42ac-8c40-ace4b10fbc8b"
        },
        {
          "id": "55bac86b-b095-4842-9532-df05aa27f313",
          "slug": "roast-beef",
          "url": "/v2/menu/roast-beef",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/1d32ebcba293db1f34f571618554ed306abfc910-550x358.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Roast Beef",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-55bac86b-b095-4842-9532-df05aa27f313",
          "__legacyUrl": "/menu/picker-55bac86b-b095-4842-9532-df05aa27f313"
        },
        {
          "id": "d6c0f573-718b-4c91-b236-2cc2c43e5135",
          "slug": "grilled-chicken-breast",
          "url": "/v2/menu/grilled-chicken-breast",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/5ef3e94a0dfd90724c04a08183ed40d042404df0-2000x1299.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Grilled Chicken Breast",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-d6c0f573-718b-4c91-b236-2cc2c43e5135",
          "__legacyUrl": "/menu/picker-d6c0f573-718b-4c91-b236-2cc2c43e5135"
        },
        {
          "id": "1ca5976d-1ffd-40e1-8a74-515c58823c3b",
          "slug": "veggie",
          "url": "/v2/menu/veggie",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/9ffa42e04bb285fbd015e091693a25fbf0ac7fe7-550x360.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Veggie",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-1ca5976d-1ffd-40e1-8a74-515c58823c3b",
          "__legacyUrl": "/menu/picker-1ca5976d-1ffd-40e1-8a74-515c58823c3b"
        }
      ]
    },
    {
      "id": "e48cd45d-0863-4dca-b537-8043e27fcefd",
      "displayName": "Cold Subs",
      "description": "",
      "slug": "cold-subs",
      "url": "/v2/menu?cold-subs",
      "items": [
        {
          "id": "33b5d003-e1e4-4e11-b65a-86c297f48a4d",
          "slug": "tuna-salad",
          "url": "/v2/menu/tuna-salad",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/bf9c8d835b701a945abd831009542af1bf37faf8-2000x1299.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Tuna Salad",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-33b5d003-e1e4-4e11-b65a-86c297f48a4d",
          "__legacyUrl": "/menu/picker-33b5d003-e1e4-4e11-b65a-86c297f48a4d"
        }
      ]
    },
    {
      "id": "9523a85a-934f-4d41-b233-45f141825bd4",
      "displayName": "Small Groups",
      "description": "",
      "slug": "small-groups",
      "url": "/v2/menu?small-groups",
      "items": [
        {
          "id": "0fb26817-0169-4bcc-b00d-a91c07c78269",
          "slug": "standard-half-platter-subs-snacks",
          "url": "/v2/menu/standard-half-platter-subs-snacks",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/63ca865686dc42374ba1ae623662bc57ea7d7359-2200x1760.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Standard Half Platter: Subs & Snacks",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-0fb26817-0169-4bcc-b00d-a91c07c78269",
          "__legacyUrl": "/menu/item-0fb26817-0169-4bcc-b00d-a91c07c78269"
        },
        {
          "id": "6330978f-a33d-42f0-82d5-b2c8f7859e70",
          "slug": "deluxe-half-platter-subs-snacks",
          "url": "/v2/menu/deluxe-half-platter-subs-snacks",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/63ca865686dc42374ba1ae623662bc57ea7d7359-2200x1760.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Deluxe Half Platter: Subs & Snacks",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-6330978f-a33d-42f0-82d5-b2c8f7859e70",
          "__legacyUrl": "/menu/item-6330978f-a33d-42f0-82d5-b2c8f7859e70"
        },
        {
          "id": "0e470210-79b1-4f7b-b59e-19f69524b047",
          "slug": "standard-platter",
          "url": "/v2/menu/standard-platter",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/4707579fcca59c32c8d8a8df1dcd6c7d6f66b05e-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Standard Platter",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-0e470210-79b1-4f7b-b59e-19f69524b047",
          "__legacyUrl": "/menu/item-0e470210-79b1-4f7b-b59e-19f69524b047"
        },
        {
          "id": "d18cc162-d4a7-432f-8768-4053c0c2f1ed",
          "slug": "deluxe-platter",
          "url": "/v2/menu/deluxe-platter",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/381ebb6ed2ed4809efad4bc9e5284966cb5115a6-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Deluxe Platter",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-d18cc162-d4a7-432f-8768-4053c0c2f1ed",
          "__legacyUrl": "/menu/item-d18cc162-d4a7-432f-8768-4053c0c2f1ed"
        },
        {
          "id": "1dbb2466-1b55-45b2-ae0d-619c3ba70659",
          "slug": "standard-platter-pack",
          "url": "/v2/menu/standard-platter-pack",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/1c22a6bcb16cc53bb51b491cbed3799c35eb5a16-400x320.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Standard Platter Pack",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-1dbb2466-1b55-45b2-ae0d-619c3ba70659",
          "__legacyUrl": "/menu/item-1dbb2466-1b55-45b2-ae0d-619c3ba70659"
        },
        {
          "id": "c17c513e-897a-4491-8db7-189d908bada5",
          "slug": "deluxe-platter-pack",
          "url": "/v2/menu/deluxe-platter-pack",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/c3b6e2893f43e24ad1d1f3bf120ad72ea4a6037d-400x320.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Deluxe Platter Pack",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-c17c513e-897a-4491-8db7-189d908bada5",
          "__legacyUrl": "/menu/item-c17c513e-897a-4491-8db7-189d908bada5"
        },
        {
          "id": "088ea4bd-672b-45de-a7c8-f8a534c87397",
          "slug": "gallon-of-unsweet-tea",
          "url": "/v2/menu/gallon-of-unsweet-tea",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/7e72ce4f6c49c6afaea6105278bcaca28ecd607a-800x1200.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Gallon of Unsweet Tea",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-088ea4bd-672b-45de-a7c8-f8a534c87397",
          "__legacyUrl": "/menu/item-088ea4bd-672b-45de-a7c8-f8a534c87397"
        },
        {
          "id": "220eda09-85b0-4103-8d3f-b098e79c95d8",
          "slug": "gallon-of-sweet-tea",
          "url": "/v2/menu/gallon-of-sweet-tea",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/7e72ce4f6c49c6afaea6105278bcaca28ecd607a-800x1200.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Gallon of Sweet Tea",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-220eda09-85b0-4103-8d3f-b098e79c95d8",
          "__legacyUrl": "/menu/item-220eda09-85b0-4103-8d3f-b098e79c95d8"
        },
        {
          "id": "b9e8ce82-9091-407e-8125-1f0dd7ad7db2",
          "slug": "gallon-of-lemonade",
          "url": "/v2/menu/gallon-of-lemonade",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/2a7ebb74b9db3c6cdec890aa4f2374c163b461c0-800x1200.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Gallon of Lemonade",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-b9e8ce82-9091-407e-8125-1f0dd7ad7db2",
          "__legacyUrl": "/menu/item-b9e8ce82-9091-407e-8125-1f0dd7ad7db2"
        }
      ]
    },
    {
      "id": "e9a194b6-9182-4a08-a364-a3cf21c28e78",
      "displayName": "Salads",
      "description": "",
      "slug": "salads",
      "url": "/v2/menu?salads",
      "items": [
        {
          "id": "7a679460-a8d1-44ff-b66c-c0ea389afb2c",
          "slug": "hook-ladder-salad",
          "url": "/v2/menu/hook-ladder-salad",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/554989f503f7b6d76211cea9bcf045f4a8fdeb39-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Hook & Ladder Salad",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "item-7a679460-a8d1-44ff-b66c-c0ea389afb2c",
          "__legacyUrl": "/menu/item-7a679460-a8d1-44ff-b66c-c0ea389afb2c"
        },
        {
          "id": "e3e4b746-5faa-4aa0-963a-a1813c62778c",
          "slug": "italian-with-grilled-chicken-salad",
          "url": "/v2/menu/italian-with-grilled-chicken-salad",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/065c4ceb4ff63a42f78a3717d30c0c61d5802906-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Italian with Grilled Chicken Salad",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-e3e4b746-5faa-4aa0-963a-a1813c62778c",
          "__legacyUrl": "/menu/item-e3e4b746-5faa-4aa0-963a-a1813c62778c"
        },
        {
          "id": "f480e445-e7fb-4cab-af51-b0291d613bc4",
          "slug": "firehouse-salad-with-ham",
          "url": "/v2/menu/firehouse-salad-with-ham",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/420968ebe140a2559f9e71b33ee9d61f12dc848a-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Firehouse Salad with Ham",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "item-f480e445-e7fb-4cab-af51-b0291d613bc4",
          "__legacyUrl": "/menu/item-f480e445-e7fb-4cab-af51-b0291d613bc4"
        },
        {
          "id": "db12268d-cd05-49dd-ae7e-31d579cbac10",
          "slug": "firehouse-salad-with-turkey",
          "url": "/v2/menu/firehouse-salad-with-turkey",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/420968ebe140a2559f9e71b33ee9d61f12dc848a-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Firehouse Salad with Turkey",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "item-db12268d-cd05-49dd-ae7e-31d579cbac10",
          "__legacyUrl": "/menu/item-db12268d-cd05-49dd-ae7e-31d579cbac10"
        },
        {
          "id": "f965ba8a-0726-4bd8-99a7-caebba2237f7",
          "slug": "firehouse-salad-with-grilled-chicken",
          "url": "/v2/menu/firehouse-salad-with-grilled-chicken",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/c314db201457f7eb89cd00cca7c2ebadc62f5f02-460x461.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Firehouse Salad with Grilled Chicken",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "item-f965ba8a-0726-4bd8-99a7-caebba2237f7",
          "__legacyUrl": "/menu/item-f965ba8a-0726-4bd8-99a7-caebba2237f7"
        },
        {
          "id": "d984a2e4-c7ac-47f8-9b79-e1091b3604d1",
          "slug": "firehouse-salad",
          "url": "/v2/menu/firehouse-salad",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/420968ebe140a2559f9e71b33ee9d61f12dc848a-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Firehouse Salad",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "item-d984a2e4-c7ac-47f8-9b79-e1091b3604d1",
          "__legacyUrl": "/menu/item-d984a2e4-c7ac-47f8-9b79-e1091b3604d1"
        }
      ]
    },
    {
      "id": "3b0deaa7-e36c-463c-8211-19370c41ebd7",
      "displayName": "Sides and Dessert",
      "description": "",
      "slug": "sides-and-dessert",
      "url": "/v2/menu?sides-and-dessert",
      "items": [
        {
          "id": "eb7e7cdf-f64d-4b85-acc3-056c2e8be874",
          "slug": "star-spangled-brownie",
          "url": "/v2/menu/star-spangled-brownie",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/f57ebd91c9537d89e9216c64ce97f26e28fbbde3-1000x800.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Star-Spangled Brownie",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-eb7e7cdf-f64d-4b85-acc3-056c2e8be874",
          "__legacyUrl": "/menu/item-eb7e7cdf-f64d-4b85-acc3-056c2e8be874"
        },
        {
          "id": "72a06b33-575c-40cc-a5bf-592f341ef4b3",
          "slug": "side-salad",
          "url": "/v2/menu/side-salad",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/332985e1428be4cb204adc61699d4a2799a6009e-1940x1260.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Side Salad",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-72a06b33-575c-40cc-a5bf-592f341ef4b3",
          "__legacyUrl": "/menu/item-72a06b33-575c-40cc-a5bf-592f341ef4b3"
        },
        {
          "id": "2edd4f20-69a1-4eb0-9648-f58494be7116",
          "slug": "firehouse-chili",
          "url": "/v2/menu/firehouse-chili",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/c74ffb28f1e69250489ddafb7d465ce8686697c4-1280x1205.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Firehouse Chili",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-2edd4f20-69a1-4eb0-9648-f58494be7116",
          "__legacyUrl": "/menu/picker-2edd4f20-69a1-4eb0-9648-f58494be7116"
        },
        {
          "id": "d35efb92-2b15-4faf-a64d-dd7e918bd401",
          "slug": "loaded-potato-soup",
          "url": "/v2/menu/loaded-potato-soup",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/254e2d40fe6b94071ceaddd4fd125a68c6f470f9-1894x1536.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Loaded Potato Soup",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "picker-d35efb92-2b15-4faf-a64d-dd7e918bd401",
          "__legacyUrl": "/menu/picker-d35efb92-2b15-4faf-a64d-dd7e918bd401"
        },
        {
          "id": "968cfc84-750f-44e6-ae64-937d1f0cb1a8",
          "slug": "chicken-and-dumpling-soup",
          "url": "/v2/menu/chicken-and-dumpling-soup",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/53152b0800d01b3db603bae2ef1f4da4072d9168-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Chicken and Dumpling Soup",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "picker-968cfc84-750f-44e6-ae64-937d1f0cb1a8",
          "__legacyUrl": "/menu/picker-968cfc84-750f-44e6-ae64-937d1f0cb1a8"
        },
        {
          "id": "c072bdbb-bda6-4d8f-b56a-0d362b615d75",
          "slug": "five-cheese-mac-cheese",
          "url": "/v2/menu/five-cheese-mac-cheese",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/8d34c69b415dc5741ef190fdd4efa8e762ea61dc-1920x1080.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Five Cheese Mac & Cheese",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-c072bdbb-bda6-4d8f-b56a-0d362b615d75",
          "__legacyUrl": "/menu/item-c072bdbb-bda6-4d8f-b56a-0d362b615d75"
        },
        {
          "id": "c882165b-7c16-4d5b-929b-85d941aedda3",
          "slug": "chips",
          "url": "/v2/menu/chips",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/3f4c2348d784577d608bb996a65f3680036c2da1-460x460.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Chips",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-c882165b-7c16-4d5b-929b-85d941aedda3",
          "__legacyUrl": "/menu/picker-c882165b-7c16-4d5b-929b-85d941aedda3"
        },
        {
          "id": "941c4f5d-f8f1-4db2-b542-470b145c2feb",
          "slug": "dessert-bundle",
          "url": "/v2/menu/dessert-bundle",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/f25e896bd336ccf07a1345357b17047ca89031e4-404x399.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Dessert Bundle",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-941c4f5d-f8f1-4db2-b542-470b145c2feb",
          "__legacyUrl": "/menu/picker-941c4f5d-f8f1-4db2-b542-470b145c2feb"
        },
        {
          "id": "0228c05a-c1f6-4dc1-b5de-24b34015f6b1",
          "slug": "cookies",
          "url": "/v2/menu/cookies",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/7372216c20740ec71e1d62d7437d874cd5e03acf-1280x1280.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Cookies",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-0228c05a-c1f6-4dc1-b5de-24b34015f6b1",
          "__legacyUrl": "/menu/picker-0228c05a-c1f6-4dc1-b5de-24b34015f6b1"
        },
        {
          "id": "e50ed0b1-49e8-4f81-89cd-2bd0d614cbf3",
          "slug": "brownie",
          "url": "/v2/menu/brownie",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/03be48b11fd4cfdcb7048a99be6c0ecd4949db4e-460x460.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Brownie",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-e50ed0b1-49e8-4f81-89cd-2bd0d614cbf3",
          "__legacyUrl": "/menu/item-e50ed0b1-49e8-4f81-89cd-2bd0d614cbf3"
        },
        {
          "id": "b6a6e53e-2471-4cf8-b173-0f6e89a128a8",
          "slug": "captain-sorensens-datil-pepper-hot-sauce",
          "url": "/v2/menu/captain-sorensens-datil-pepper-hot-sauce",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/311075e6a5b3be311c22f183b048313b8d139d0e-600x479.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Captain Sorensen's Datil Pepper Hot Sauce",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-b6a6e53e-2471-4cf8-b173-0f6e89a128a8",
          "__legacyUrl": "/menu/item-b6a6e53e-2471-4cf8-b173-0f6e89a128a8"
        }
      ]
    },
    {
      "id": "acb168c3-20a6-41ec-a224-580d791f6f0f",
      "displayName": "Beverages",
      "description": "",
      "slug": "beverages",
      "url": "/v2/menu?beverages",
      "items": [
        {
          "id": "item_44115",
          "slug": "bottle-coca-cola",
          "url": "/v2/menu/bottle-coca-cola",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/eb0fdd1e38415d2f8c0c5d50af5a4a90d343757d-2000x1333.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Bottle Coca-Cola®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-item_44115",
          "__legacyUrl": "/menu/item-item_44115"
        },
        {
          "id": "item_44116",
          "slug": "bottle-diet-coke",
          "url": "/v2/menu/bottle-diet-coke",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/528bda182c99c887e1a8b518036d7aef9e991504-2000x1333.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Bottle Diet Coke®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-item_44116",
          "__legacyUrl": "/menu/item-item_44116"
        },
        {
          "id": "item_44117",
          "slug": "bottle-sprite",
          "url": "/v2/menu/bottle-sprite",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/41183890f946f86fa813a6565b4e628c2b598290-2000x1333.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Bottle Sprite®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-item_44117",
          "__legacyUrl": "/menu/item-item_44117"
        },
        {
          "id": "b8a0a030-2bc8-4275-9523-d7ed044f5d67",
          "slug": "bottle-zero-coke",
          "url": "/v2/menu/bottle-zero-coke",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/b16e511a2bdd8c0e46f066d8d6924ca6aff3d107-550x367.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Bottle Zero Coke®",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-b8a0a030-2bc8-4275-9523-d7ed044f5d67",
          "__legacyUrl": "/menu/item-b8a0a030-2bc8-4275-9523-d7ed044f5d67"
        },
        {
          "id": "67c4cc12-ae49-4920-84e1-75e07d0df22a",
          "slug": "bottled-water",
          "url": "/v2/menu/bottled-water",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/eeb1c7f227c87458dc25e472115c05a024d55d51-460x460.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Bottled Water",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "item-67c4cc12-ae49-4920-84e1-75e07d0df22a",
          "__legacyUrl": "/menu/item-67c4cc12-ae49-4920-84e1-75e07d0df22a"
        },
        {
          "id": "981c5eee-02c1-4d33-8f7a-b4a2264831fd",
          "slug": "bottled-orange-juice",
          "url": "/v2/menu/bottled-orange-juice",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/805f83b470154cbc2e03c12cf6aaa46a3c42ebd2-1200x1200.jpg?w=275&q=80&fit=max&auto=format",
          "displayName": "Bottled Orange Juice",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "item-981c5eee-02c1-4d33-8f7a-b4a2264831fd",
          "__legacyUrl": "/menu/item-981c5eee-02c1-4d33-8f7a-b4a2264831fd"
        },
        {
          "id": "3ef02fba-21ed-4615-bf7c-58ed5d7f8814",
          "slug": "fountain-drink",
          "url": "/v2/menu/fountain-drink",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/46e135c5c5d60a2cf2df9148799eee1d271068d5-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Fountain Drink",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "picker-3ef02fba-21ed-4615-bf7c-58ed5d7f8814",
          "__legacyUrl": "/menu/picker-3ef02fba-21ed-4615-bf7c-58ed5d7f8814"
        }
      ]
    },
    {
      "id": "207f1456-5988-4da5-8019-83d959558f4f",
      "displayName": "Kids' Combos",
      "description": "",
      "slug": "kids-combos",
      "url": "/v2/menu?kids-combos",
      "items": [
        {
          "id": "76fd0d1a-7339-4ee4-b538-2ebda3d9c97a",
          "slug": "combo-hot-turkey",
          "url": "/v2/menu/combo-hot-turkey",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/6db4181a65d2f544674d28b8e8f0152560bdb1aa-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Combo Hot Turkey ",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": false,
          "__legacySlug": "combo-76fd0d1a-7339-4ee4-b538-2ebda3d9c97a",
          "__legacyUrl": "/menu/combo-76fd0d1a-7339-4ee4-b538-2ebda3d9c97a"
        },
        {
          "id": "ef9946e7-91df-4938-9d66-073f738ecd42",
          "slug": "combo-hot-ham",
          "url": "/v2/menu/combo-hot-ham",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/8c4c8e685799a1f496aeb30257a12eb130e157bd-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Combo Hot Ham",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "combo-ef9946e7-91df-4938-9d66-073f738ecd42",
          "__legacyUrl": "/menu/combo-ef9946e7-91df-4938-9d66-073f738ecd42"
        },
        {
          "id": "5f688036-a0f6-483b-ab4f-d38078c288fe",
          "slug": "combo-hot-grilled-cheese",
          "url": "/v2/menu/combo-hot-grilled-cheese",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/0a7a1c072ba38b05e037f02da2d69b1eb2912263-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Combo Hot Grilled Cheese",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "combo-5f688036-a0f6-483b-ab4f-d38078c288fe",
          "__legacyUrl": "/menu/combo-5f688036-a0f6-483b-ab4f-d38078c288fe"
        },
        {
          "id": "6d72e39c-0c5b-47d2-abd1-02e4ce07edd4",
          "slug": "combo-hot-meatball",
          "url": "/v2/menu/combo-hot-meatball",
          "imageUrl": "https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/0da742964a5c854acae0ae1fc67652755b5a5505-600x480.png?w=275&q=80&fit=max&auto=format",
          "displayName": "Combo Hot Meatball",
          "descriptionShort": "Short description to be added to Sanity",
          "isAvailable": true,
          "__legacySlug": "combo-6d72e39c-0c5b-47d2-abd1-02e4ce07edd4",
          "__legacyUrl": "/menu/combo-6d72e39c-0c5b-47d2-abd1-02e4ce07edd4"
        }
      ]
    }
  ]
}