import { Image } from 'expo-image';
import { StyleSheet, type ViewProps } from 'react-native';

import { ActionSheet, Button, IconChevronRight, Text, XStack, YStack, tokens } from '@fhs/ui';

type TItem = {
  from: { image: string; name: string; blurhash: string };
  to: { image: string; name: string; blurhash: string };
};

type TIngredientReplacement = ViewProps & {
  isVisible: boolean;
  headerTitle?: string;
  acceptButtonTitle: string;
  closeButtonTitle: string;
  items: Array<TItem>;
  handleReplace: () => void;
  handleClose: () => void;
  sizeLabel: string;
};

const generateUnavailableMessage = (items: Array<TItem>, sizeLabel: string) => {
  const itemNames = items.map(item => item.from.name);

  // Create a comma-separated list, with "and" before the last item
  const formattedItems =
    itemNames.length > 1
      ? `${itemNames.slice(0, -1).join(', ')} and ${itemNames.slice(-1)}`
      : itemNames[0];

  return (
    <>
      <Text.Paragraph size="sm" weight="semibold">
        {formattedItems}
      </Text.Paragraph>
      <Text.Paragraph size="sm"> is unavailable in </Text.Paragraph>
      <Text.Paragraph size="sm" weight="semibold">
        {sizeLabel}
      </Text.Paragraph>
      <Text.Paragraph size="sm"> size.</Text.Paragraph>
    </>
  );
};

export function IngredientReplacement({
  isVisible,
  headerTitle,
  acceptButtonTitle,
  closeButtonTitle,
  handleReplace,
  handleClose,
  sizeLabel,
  items,
}: TIngredientReplacement): JSX.Element {
  const unavailableMessage = generateUnavailableMessage(items, sizeLabel);
  return (
    <ActionSheet isVisible={isVisible} onClose={handleClose}>
      <YStack style={styles.container}>
        {headerTitle && (
          <Text.Ui size="lg" weight="semibold" style={{ marginBottom: '3%' }}>
            {headerTitle}
          </Text.Ui>
        )}
        <Text.Ui size="sm" style={styles.description}>
          {unavailableMessage}
        </Text.Ui>
        <Text.Ui size="sm" style={[styles.description, { marginBottom: '3%' }]}>
          Do you want to replace this ingredient?
        </Text.Ui>

        {items.map(item => (
          <XStack key={`${item.from.name}-${item.to.name}`} style={styles.itemsContainer}>
            <XStack style={styles.itemContainer}>
              <Image
                source={{ uri: item.from.image }}
                alt={item.from.name}
                contentFit="contain"
                style={{
                  height: 50,
                  width: 40,
                  opacity: 0.5,
                }}
                placeholder={{ blurhash: item.from.blurhash }}
              />
              <Text.Paragraph
                size="sm"
                weight="semibold"
                ellipsizeMode="tail"
                numberOfLines={1}
                style={{ color: tokens.colors.$blackOpacity55, width: '60%' }}
              >
                {item.from.name}
              </Text.Paragraph>
            </XStack>
            <IconChevronRight style={styles.greaterThanContainer} size={30} />
            <XStack key={item.to.name} style={styles.itemContainer}>
              <Image
                source={{ uri: item.to.image }}
                alt={item.to.name}
                contentFit="contain"
                style={{
                  height: 50,
                  width: 40,
                }}
                placeholder={{ blurhash: item.to.blurhash }}
              />
              <Text.Paragraph
                size="sm"
                weight="semibold"
                ellipsizeMode="tail"
                numberOfLines={1}
                style={{ width: '60%' }}
              >
                {item.to.name}
              </Text.Paragraph>
            </XStack>
          </XStack>
        ))}

        <Button size="lg" onPress={handleReplace} style={styles.button}>
          <Button.Text>{acceptButtonTitle}</Button.Text>
        </Button>
        <Button size="lg" type="outline" onPress={handleClose} style={styles.button}>
          <Button.Text>{closeButtonTitle}</Button.Text>
        </Button>
      </YStack>
    </ActionSheet>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
  generateUnavailableMessage: {
    width: '100%',
  },
  button: {
    minWidth: '80%',
  },
  description: {
    maxWidth: '80%',
    textAlign: 'center',
  },
  itemsContainer: {
    marginBottom: '3%',
    alignItems: 'center',
  },
  itemContainer: {
    width: '45%',
    alignItems: 'center',
    padding: 5,
    borderRadius: 8,
    borderWidth: 1,
    gap: 12,
    borderColor: tokens.colors.$blackOpacity10,
  },
  greaterThanContainer: {
    alignItems: 'center',
    padding: 10,
  },
});
