import { type FlashList } from '@shopify/flash-list';
import { type ReactNode, useMemo, useRef, useState } from 'react';

import { anchorSectionListContext } from './context';
import { ItemSizeType, SectionType } from './types';

type AnchorSectionListProps = {
  sections: Array<SectionType>;
  size?: ItemSizeType;
  children: ReactNode;
};
export function AnchorSectionList(props: AnchorSectionListProps) {
  const flashListRef = useRef<FlashList<any>>(null);

  const [activeSectionId, setActiveSectionId] = useState('');
  const contextValue = useMemo(() => {
    return {
      sections: props.sections,
      size: props.size ?? 'lg',
      flashListRef,
      activeSectionId,
      setActiveSectionId,
      flashListData: props.sections.flatMap(section => [
        { type: 'section', key: section.id, section } as const,
        ...(Array.isArray(section.items)
          ? section.items.map(
              (item, indexWithinSection) =>
                ({
                  type: 'item',
                  key: item.id,
                  parentSectionId: section.id,
                  indexWithinSection,
                  item,
                } as const)
            )
          : [{ type: 'custom', key: section.id, section } as const]),
      ]),
    };
  }, [props.sections, props.size, flashListRef, activeSectionId, setActiveSectionId]);

  return (
    <anchorSectionListContext.Provider value={contextValue}>
      {props.children}
    </anchorSectionListContext.Provider>
  );
}
