import { CartModel, Discount } from '@fhs/cart';
import { computeSubtotal } from '@fhs/cart/src/utils/compute-subtotal';

import { GetLoyaltyIncentivesParams, LoyaltyIncentives, OfferMock } from '../types';

import { data, offers, rewards } from './mock.data';

export async function receiveDiscounts(cart: CartModel): Promise<Discount[]> {
  const incentive = cart.appliedIncentives[0];

  switch (incentive.type) {
    case 'GLOBAL_DISCOUNT':
      return [
        {
          appliedCartEntries: [],
          savingsAmount: 400,
          type: 'GLOBAL_DISCOUNT',
        },
      ];
    case 'REWARD':
      const entryId = cart.appliedIncentives[0].appliedCartEntries[0];
      const entry = cart.entries.find(({ lineId }) => lineId === entryId);
      return [
        {
          appliedCartEntries: cart.appliedIncentives[0].appliedCartEntries,
          savingsAmount: entry?.price ?? 0,
          type: 'REWARD',
        },
      ];
    default:
      return [];
  }
}

export async function validateCart(
  cart: CartModel
): Promise<{ state: 'VALID' | 'LOYALTY_INVALID' }> {
  if (cart.appliedIncentives.length > 0 && cart.entries.length === 0) {
    return { state: 'LOYALTY_INVALID' };
  }

  if (computeSubtotal(cart) === 0) {
    return {
      state: 'LOYALTY_INVALID',
    };
  }

  return {
    state: 'VALID',
  };
}

export async function getOffer(offerId: string): Promise<OfferMock> {
  const offer = data.find(o => o.id === offerId);
  if (offer) {
    return Promise.resolve(offer);
  }
  throw new Error('Could not find offer');
}

export async function getRewardsOffer(_itemId: string): Promise<OfferMock> {
  return {
    name: 'Using 6,000 Points',
    id: 'reward-6000',
    description: '',
    expiration: '',
    imageUrl: '',
    type: 'REWARD', // TODO: Type this properly
  };
}

// Loyalty will give us the cost per item in points and if it's
// able to be redeemed as a reward
export async function receivePointCosts(cart: CartModel): Promise<
  {
    lineId: string;
    points: number;
    rewardIsRedeemable: boolean;
  }[]
> {
  return cart.entries.map(entry => ({
    lineId: entry.lineId,
    points: 6000,
    rewardIsRedeemable: cart.appliedIncentives.length === 0,
  }));
}

export async function getLoyaltyIncentives(
  _: GetLoyaltyIncentivesParams
): Promise<LoyaltyIncentives> {
  await sleep(1000);

  return {
    rewards,
    offers,
  };
}

const sleep = (time: number) => new Promise(resolve => setTimeout(resolve, time));
