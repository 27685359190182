import { ScrollView, ScrollViewProps, StyleSheet, View } from 'react-native';

import { tokens } from '@fhs/ui';

export type MenuListTopbarProps = Omit<
  ScrollViewProps,
  'horizontal' | 'showsHorizontalScrollIndicator'
>;
export function MenuListTopbar(props: MenuListTopbarProps) {
  return (
    <ScrollView
      {...props}
      horizontal
      showsHorizontalScrollIndicator={false}
      style={[styles.scrollView, props.style]}
      contentContainerStyle={[styles.scrollViewContent, props.contentContainerStyle]}
    >
      {props.children}

      <SpaceFiller />
    </ScrollView>
  );
}

const SpaceFiller = () => <View style={styles.spaceFiller} />;

const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 0,
    backgroundColor: tokens.colors.$white,
    minWidth: '100%',
  },
  scrollViewContent: {
    minWidth: '100%',
  },
  spaceFiller: {
    flexGrow: 9999,
    flexShrink: 0,
    borderBottomWidth: 3,
    borderColor: tokens.colors.$blackOpacity10,
  },
});
